<template>
  <v-container id="regular-tables" fluid tag="section">
    <base-material-card
      icon="mdi-help-circle"
      title="consultations List"
      class="px-5 py-3">
      <v-form ref="downloadReportForm">
        <v-row>
          <v-col cols="4" sm="4">
            <v-menu
              v-model="menu_date_from"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_from"
                  label="Select date from"
                  append-icon="mdi-calendar-range"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date_from"
                @input="menu_date_from = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" sm="4">
            <v-menu
              v-model="menu_date_to"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_to"
                  label="Select date to"
                  append-icon="mdi-calendar-range"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_to"
                @input="menu_date_to = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" sm="2">
            <v-btn
              block
              :disabled="!date_from || !date_to"
              large
              color="primary"
              @click="filterOnDate()"
            >
              Filter
            </v-btn>
          </v-col>
          <v-col cols="2" sm="2">
            <v-btn
              block
              :disabled="!date_from || !date_to"
              large
              color="grey lighten-1"
              @click="clearDateFilter()"
            >
              Clear
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="blue"
      ></v-progress-linear>
      <v-simple-table>
        <filter-list
          :filters="filter"
          :on-change="getConsultations">
        </filter-list>
        <thead>
        <tr>
          <th class="primary--text">
            Consultation ID
          </th>
          <th class="primary--text">
            National ID
          </th>
          <th class="primary--text">
            Patient Name
          </th>
          <th class="primary--text">
            Dr. Name
          </th>
          <th class="primary--text">
            Phone Number
          </th>
          <th class="primary--text">
            Insurance No.
          </th>
          <th class="primary--text">
            Policy No.
          </th>
          <th class="primary--text">
            Has Labs
          </th>
          <th class="primary--text">
            Labs Status
          </th>
          <th class="primary--text">
            Has Medications
          </th>
          <th class="primary--text">
            Medications Status
          </th>
          <th class="primary--text">
            Has Referral
          </th>
          <th class="primary--text">
            Referral Status
          </th>
          <th class="primary--text">
            Is Emergency
          </th>
          <th class="primary--text">
            Created At
          </th>
          <th class="text-center primary--text">
            Options
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="consultation in consultations" :bgcolor="consultation.twConsultationFiltering.seen_at ? '#fff': '#eee'">
          <td>
            {{ consultation.consultation_id }}
          </td>
          <td>
            {{ consultation.phr.national_no ? consultation.phr.national_no : 'Not set' }}
          </td>
          <td>
            {{ consultation.phr.name ? consultation.phr.name : consultation.member.fullName}}
          </td>
          <td>
            {{ consultation.location.nameWithPrefix }}
          </td>
          <td>
            {{ consultation.phr.phone ? consultation.phr.phone : consultation.member.phone }}
          </td>
          <td>
            {{ consultation.phr.insurance_no }}
          </td>
          <td>
            {{ consultation.phr.policy_no }}
          </td>
          <td>
            {{ consultation.twConsultationFiltering.has_labs ? 'Yes' : 'No' }}
          </td>
          <td>
            {{ consultation.twConsultationFiltering.has_labs_status ? consultation.twConsultationFiltering.has_labs_status: 'Not set'}}
          </td>
          <td>
            {{ consultation.twConsultationFiltering.has_medications ? 'Yes' : 'No' }}
          </td>
          <td>
            {{ consultation.twConsultationFiltering.has_medications_status ? consultation.twConsultationFiltering.has_medications_status : 'Not set' }}
          </td>
          <td>
            {{ consultation.twConsultationFiltering.has_referral ? 'Yes' : 'No' }}
          </td>
          <td>
            {{ consultation.twConsultationFiltering.has_referral_status ? consultation.twConsultationFiltering.has_referral_status : 'Not set' }}
          </td>
          <td>
            {{ consultation.twConsultationFiltering.is_emergency ? 'Yes' : 'No' }}
          </td>
          <td>
            {{ consultation.date_added }}
          </td>
          <td class="text-center">
            <div class="d-inline-flex">
              <v-btn x-small fab color="info" class="mr-2" :to="{ path: '/consultation-referrals/'+ consultation.consultation_id}">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="text-center">
      <v-pagination
        v-model="params.page"
        :length="pagination.length"
        :total-visible="7"
        @input="getConsultations"
        @next="getConsultations"
        @previous="getConsultations"
      />
    </div>
    <div class="py-3"/>
    <v-btn x-large fab dark fixed right bottom color="blue"  to="/consultations/create" v-if="$auth.getEnabledFeatures().includes('create_consultation')">
      <v-icon large>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<style scoped>

</style>

<script>
import VValidationErrors from "@/components/base/VValidationErrors.vue";
import {VueTelInput} from "vue-tel-input";
import FilterList from "@/components/base/FilterList.vue";

export default {
  name: 'ConsultationReferrals',
  components: {
    FilterList,
    VValidationErrors,
    VueTelInput,
  },
  created () {
    this.getUrlParams();
    this.getConsultations();
  },
  watch: {
      $route() {
          this.getUrlParams();
          this.getConsultations();
      }
  },
  data () {
    return {
      date_from: '',
      date_to: '',
      menu_date_from: false,
      menu_date_to: false,
      loading: false,
      countryCode: '',
      mobile: '',
      params: {
        page: 1,
      },
      pagination: {
        length: 0,
        total: 0
      },
      consultations: [],
      validationErrors: {},
      filter: [
        {
          name: "consultation_id",
          value: "",
          type: "text_field"
        },
        {
          name: "national_no",
          value: "",
          type: "text_field"
        },
        {
          name: "patient_name",
          value: "",
          type: "text_field"
        },
        {
          name: "doctor_name",
          value: "",
          type: "text_field"
        },
        {
          name: "phone",
          value: "",
          type: "text_field"
        },
        {
          name: "insurance_no",
          value: "",
          type: "text_field"
        },
        {
          name: "policy_no",
          value: "",
          type: "text_field"
        },
        {
          name: "has_labs",
          value: "",
          type: "select",
          items: [
            {text: "Yes", value: 1},
            {text: "No", value: 0}
          ]
        },
        {
          name: "has_labs_status",
          value: "",
          type: "select",
          items: [
            {text: "In-Progress", value: 'In-Progress'},
            {text: "Approved", value: 'Approved'},
            {text: "Rejected", value: 'Rejected'},
            {text: "Completed", value: 'Completed'}
          ]
        },
        {
          name: "has_medications",
          value: "",
          type: "select",
          items: [
            {text: "Yes", value: 1},
            {text: "No", value: 0}
          ]
        },
        {
          name: "has_medications_status",
          value: "",
          type: "select",
          items: [
            {text: "In-Progress", value: 'In-Progress'},
            {text: "Approved", value: 'Approved'},
            {text: "Rejected", value: 'Rejected'},
            {text: "Completed", value: 'Completed'}
          ]
        },
        {
          name: "has_referral",
          value: "",
          type: "select",
          items: [
            {text: "Yes", value: 1},
            {text: "No", value: 0}
          ]
        },
        {
          name: "has_referral_status",
          value: "",
          type: "select",
          items: [
            {text: "In-Progress", value: 'In-Progress'},
            {text: "Approved", value: 'Approved'},
            {text: "Rejected", value: 'Rejected'},
            {text: "Completed", value: 'Completed'}
          ]
        },
        {
            name: "is_emergency",
            value: "",
            type: "select",
            items: [
                {text: "Yes", value: 1},
                {text: "No", value: 0},
            ]
        },
        {
            name: "date_added",
            value: "",
            type: "text_field"
        },
        {
          name: "options",
          value: "",
          type: "options"
        },
      ]
    }
  },
  methods: {
    countrySelected (val) {
      this.countryCode = val.dialCode
    },
    filterOnDate() {
      this.params.date_from = this.date_from;
      this.params.date_to = this.date_to;
      this.getConsultations();
    },
    clearDateFilter () {
      this.date_from = null;
      this.date_to = null;
      this.params.date_to = null;
      this.params.date_from = null;
      this.getConsultations();
    },
    getConsultations (page = false) {
      if (page) {
        this.params.page = page;
      }
      this.loading = true;
      this.$authApi.get('consultations/referrals', {
        params: this.params
      }).then(res => {
        this.loading = false;
        this.consultations = res.data.data.consultations.items
        this.pagination.length = res.data.data.consultations.extra._meta.pageCount;
      }).catch(err => {
        this.loading = false;
      })
    },
    getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
            this.params = {
                page: 1
            };
            this.filter = [
                {
                    name: "consultation_id",
                    value: "",
                    type: "text_field"
                },
                {
                  name: "national_no",
                  value: "",
                  type: "text_field"
                },
                {
                  name: "patient_name",
                  value: "",
                  type: "text_field"
                },
                {
                  name: "doctor_name",
                  value: "",
                  type: "text_field"
                },
                {
                    name: "phone",
                    value: "",
                    type: "text_field"
                },
                {
                    name: "insurance_no",
                    value: "",
                    type: "text_field"
                },
                {
                    name: "policy_no",
                    value: "",
                    type: "text_field"
                },
                {
                  name: "has_labs",
                  value: "",
                  type: "select",
                  items: [
                    {text: "Yes", value: 1},
                    {text: "No", value: 0}
                  ]
                },
                {
                    name: "has_labs_status",
                    value: "",
                    type: "select",
                    items: [
                      {text: "In-Progress", value: 'In-Progress'},
                      {text: "Approved", value: 'Approved'},
                      {text: "Rejected", value: 'Rejected'},
                      {text: "Completed", value: 'Completed'}
                    ]
                },
                {
                  name: "has_medications",
                  value: "",
                  type: "select",
                  items: [
                    {text: "Yes", value: 1},
                    {text: "No", value: 0}
                  ]
                },
                {
                    name: "has_medications_status",
                    value: "",
                    type: "select",
                    items: [
                      {text: "In-Progress", value: 'In-Progress'},
                      {text: "Approved", value: 'Approved'},
                      {text: "Rejected", value: 'Rejected'},
                      {text: "Completed", value: 'Completed'}
                    ]
                },
                {
                  name: "has_referral",
                  value: "",
                  type: "select",
                  items: [
                    {text: "Yes", value: 1},
                    {text: "No", value: 0}
                  ]
                },
                {
                    name: "has_referral_status",
                    value: "",
                    type: "select",
                    items: [
                      {text: "In-Progress", value: 'In-Progress'},
                      {text: "Approved", value: 'Approved'},
                      {text: "Rejected", value: 'Rejected'},
                      {text: "Completed", value: 'Completed'}
                    ]
                },
                {
                    name: "is_emergency",
                    value: "",
                    type: "select",
                    items: [
                        {text: "Yes", value: 1},
                        {text: "No", value: 0},
                    ]
                },
                {
                    name: "date_added",
                    value: "",
                    type: "text_field"
                },
                {
                    name: "options",
                    value: "",
                    type: "options"
                },
            ];
            this.$root.$emit('refreshParams', this.filter)
        } else {
            this.params = this.$route.query;
            this.filter.forEach(param => {
                param.value = this.params[param['name']];
            });
        }
  }

  },
}

</script>
